import { version } from './version';

export const environment = {
  BASE_URL: 'https://dtesantoandre.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3547809',
  schema: 'pbi_dte_santo_andre',
  groupId: '3d75ca02-d694-4b74-85a2-d7f95df9c33d',
  analiseGeral: 'a28a64e4-db73-47e1-9d5a-9bb7624264e6',
  analiseUsuarios: '2d8a01b3-26a8-436e-86e7-c7b89ac17d91',
  analiseComunicados: 'e3064b49-2b94-46cc-a9b5-c0ce0786530f',
  analiseProcurador: '6d08e814-3c87-4ac9-b16e-ef809e3e891b',
  analiseEventos: 'c5e9560a-4435-4b4f-a8cb-99cde8cc91f6',
  analiseContribuintes: 'ce67d417-1d3e-42e9-a78a-e136dee733ec'
};
